.plan-duration-selection {
  background: #f2f2f7;
  border-radius: 20px 20px 0 0;
  padding: 16px;

  .plan-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .plan-option-button {
      width: 100%;
      margin: 0px 4px;
      border-radius: 10px;
      background-color: #fff;
      text-align: left;
      padding: 6px;
      font-size: 14px;
      text-transform: none;
      justify-content: start;
      transition: all 0.5s ease;
      .plan-option-button-container {
        height: 100%;
        .MuiCheckbox-root {
          padding: 0px;
          margin-right: 4px;
          transition: all 0.9s ease;
        }
      }
      &.active {
        background-color: #e3f2fd;
        transition: all 0.9s ease;
      }

      .monthly-price {
        font-size: 14px;
        font-weight: 600;
        b {
          font-size: 16px;
          font-weight: 800;
        }
      }
      .real-price {
        font-size: 14px;
        font-weight: 500;
      }

      @media (max-width: 450px) {
        .monthly-price {
          font-size: 12px;
          b {
            font-size: 14px;
          }
        }
        .real-price {
          font-size: 12px;
        }
      }

      .discount-badge {
        position: absolute;
        top: -18px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        span {
          background-color: #037e36;
          color: #fff;
          border-radius: 100px;
          padding: 2px 8px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
  }

  .primary-button {
    background-color: #17437d;
    color: #ffffff;
    border-radius: 50px;
    height: 48px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
  }

  .secondary-button {
    background-color: #ffffff;
    color: #595959;
    border-radius: 50px;
    height: 48px;
    text-transform: capitalize;
    font-size: 14px;
  }

  a {
    margin: 0 8px;
    color: #17437d;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
