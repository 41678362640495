.feature-accordion {
  margin: 24px 12px 12px 12px !important;
  background-color: transparent;

  .MuiPaper-elevation {
    box-shadow: none !important;
    padding: 0px !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 30px !important;
    transition: all 0.4s ease-in-out;
  }
  .expanded {
    border-radius: 12px !important;
    transition: all 0.4s ease-in-out;
  }

  .accordion-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiAccordionSummary-content {
    margin: 0px !important;
    align-items: center !important;
  }
  .MuiAccordionSummary-root {
    padding: 0px 8px;
  }
  .accordion-chip {
    margin-right: 8px;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
  .accordion-title {
    flex-grow: 1;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
  }
  .accordion-details {
    padding: 0px 16px;
  }
  .feature-list {
    list-style-type: none;
    padding: 0;
  }
  .feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    padding-left: 16px;
    position: relative;

    &:before {
      content: "";
      display: inline-block;
      width: 6px;
      height: 6px;
      background-color: #17437d; // Blue color for the bullet
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .feature-name {
      font-weight: 600;
      margin-right: 1px;
      font-size: 13px;
      color: #424242;
    }
    .feature-description {
      color: rgba(0, 0, 0, 0.6);
      font-size: 13px;
      opacity: 0.8;
      color: #424242;
    }
  }
}
