.reset_password {
  .main-box {
    padding: 16px;
    margin: 0 auto;
    /* margin-top: 30%; */
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #212121;
  }
  form {
    width: 100%;
  }

  .MuiButtonBase-root {
    padding: 15px !important;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
  }
  .pass-change-success {
    padding-bottom: 10%;
  }
  .pass-change-success .content {
    padding-left: 5%;
  }

  .pass-change-success svg {
    width: 150px !important;
  }
  .pass-change-success .content h2 {
    font-size: 40px;
  }
  .pass-change-success .content p {
    font-size: 20px;
  }

  .MuiDialog-paperScrollPaper {
    max-width: 400px !important;
    max-height: 250px !important;
  }
  @media (max-width: 450px) {
    .MuiDialog-paperScrollPaper {
      max-height: 290px !important;
    }
  }

  .italic {
    font-style: italic;
  }

  h1 {
    font-size: 30px !important;
    font-weight: 600 !important;
    color: #212121 !important;
  }
  label {
    color: #212121 !important;
  }
  .label-title {
    display: flex;
  }
  .label-title p {
    padding-left: 10px;
    color: #eb5757 !important;
  }
  button {
    background-color: black !important;
    color: white !important;
    text-transform: none !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .MuiLoadingButton-loadingIndicator span svg {
    color: white !important;
  }
  .input-text-field fieldset {
    border-color: black !important;
  }

  .input-text-field-error fieldset {
    border-color: #ff0000 !important;
  }
  svg {
    color: #212121 !important;
  }
  .MuiIconButton-edgeEnd {
    background-color: transparent !important;
  }
  .val-item {
    display: flex;
    align-items: center;
  }
  .val-item div {
    margin-right: 5px;
  }

  .validations svg {
    font-size: 10px;
    color: #212121 !important;
  }
  .validations p {
    font-size: 12px;
    color: #212121 !important;
  }
  .validations .success svg {
    font-size: 12px;
    color: #03a02e !important;
  }
  .validations .success p {
    font-size: 12px;
    color: #03a02e !important;
  }
  .validations .error svg {
    font-size: 12px;
    color: #e12111 !important;
  }
  .validations .error p {
    font-size: 12px;
    color: #e12111 !important;
  }
}
