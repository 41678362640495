.special-offer {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  padding: 20px;
  text-align: center;

  h1 {
    color: #003366;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .offer-card-container {
    margin-bottom: 20px;
  }

  .offer-card {
    background-color: white;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    border-radius: 20px;
    padding: 16px 16px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);

    .label {
      color: #616161;
      font-size: 1rem;
    }

    .from {
      color: #17437d;
      font-size: 1.4rem;
      font-weight: 600;
    }

    .code {
      color: #2f80ed;
      font-size: 1.6rem;
      font-weight: 800;
    }

    .offer-from,
    .promo-code {
      text-align: left;

      p {
        margin-bottom: 5px;
      }

      h2,
      h3 {
        margin-top: 0;
      }
    }

    .promo-code {
      margin-top: 15px;
    }
  }

  .unlock-text {
    color: #17437d;
    font-size: 1.5em;
    margin-bottom: 20px;
    font-weight: 600;
    text-align: start;
    margin-top: 24px;
  }

  .offer-details {
    text-align: left;

    p {
      margin-bottom: 10px;
    }
  }

  .icon {
    margin-right: 5px;
  }

  .checkmark {
    color: #2f80ed;
    margin-right: 5px;
  }

  .strikethrough {
    text-decoration: line-through;
    color: #000000;
    font-size: 1rem;
  }

  .discounted-price {
    font-size: 1.3rem;
    color: #000000;
    font-weight: 600;
  }

  .plan-selection {
    margin-bottom: 20px;
  }

  .planCard {
    margin-bottom: 20px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: #e6f2ff solid 1px;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }

    .plan-option {
      display: flex;
      align-items: center;
      padding: 20px;
      cursor: pointer;
      transition: all 0.3s ease;
      background-color: white;

      &:hover {
        background-color: #f8f8f8;
      }

      &.selected {
        background-color: #e6f2ff;
      }

      input[type="checkbox"] {
        margin-right: 15px;
        width: 20px;
        height: 20px;
        border: 2px solid #2f80ed;
        border-radius: 4px;
        appearance: none;
        outline: none;
        cursor: pointer;

        &:checked {
          background-color: #2f80ed;
          position: relative;

          &::after {
            content: "\2714";
            font-size: 14px;
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .plan-details {
        flex-grow: 1;
        text-align: left;

        h3 {
          margin: 0 0 5px;
          color: #17437d;
          font-size: 18px;
          font-weight: 600;
        }

        .duration,
        .discount {
          font-size: 14px;
          color: #666;
          margin: 0;
        }

        .discount {
          color: #2f80ed;
          font-weight: 600;
        }
      }

      .price-details {
        text-align: right;

        .price {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .strikethrough {
            font-size: 14px;
            color: #999;
            text-decoration: line-through;
          }

          .discounted-price {
            font-weight: bold;
            color: #17437d;
          }
        }
      }
    }

    .see-all-benefits {
      padding: 10px;
      background-color: #f8f8f8;

      button {
        background: none;
        border: none;
        color: #2f80ed;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        &:hover {
          text-decoration: underline;
        }

        svg {
          margin-left: 5px;
        }
      }
    }

    .tier-features {
      padding: 20px;
      background-color: #f8f8f8;
      text-align: left;

      h4 {
        margin-top: 0;
        color: #17437d;
      }

      ul {
        list-style-type: none;
        padding-left: 0;

        li {
          margin-bottom: 5px;
          color: #666;

          &::before {
            content: "✓";
            color: #2f80ed;
            margin-right: 5px;
          }
        }
      }
    }
    .feature-accordion {
      margin: 0 !important;
      .MuiPaper-elevation {
        border-radius: 0 !important;
        border: none !important;
        border-top: #e6f2ff solid 1px !important;
      }
    }
  }
}
