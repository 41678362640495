.transaction-history {
  .MuiAccordion-gutters {
    box-shadow: none !important;
    background-color: #fff0;
  }
  .transaction-history__accordion {
    .MuiAccordionSummary-root {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .MuiAccordionSummary-expandIcon {
      color: #0d47a1;
    }

    .transaction-history__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        margin: 0;
        color: #0d47a1;
        font-weight: 500;
      }

      .MuiIconButton-root {
        padding: 0;
      }
    }
  }

  .transaction-history__no-history {
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 16px;
    background-color: #fff;
    margin-bottom: 16px;
    text-align: center;

    h6 {
      color: #0d47a1;
      font-weight: 500;
      margin-bottom: 8px;
    }

    p {
      color: #757575;
      margin: 0;
    }

    strong {
      color: #0d47a1;
    }
  }

  .transaction-history__card {
    padding: 16px;
    border: 1px solid #d3e4ff;
    border-radius: 16px;
    background-color: #fff0;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .transaction-history__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        width: 20px;
        height: 26px;
        position: relative;
        img {
          height: 16px;
          width: 16px;
          border-radius: 3px;
        }
      }

      .title {
        flex-grow: 1;
        color: #0d47a1;
        font-weight: 500;
      }

      .amount {
        color: #0d47a1;
        font-weight: 800;
      }
    }

    .transaction-history__date {
      color: #343434;
      font-weight: 600;
    }

    .transaction-history__details {
      display: flex;
      flex-wrap: wrap;

      .label {
        color: #343434;
        margin-right: 4px;
      }

      .value {
        color: #343434;
        font-weight: 500;
      }
    }
  }
}
