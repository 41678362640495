.plan-feature-table {
  .header {
    font-weight: bold;
    text-align: center;
  }
  .feature {
    text-align: start;
  }

  .MuiTableRow-root {
    &:hover {
      background-color: rgba(3, 169, 244, 0);
    }
  }
  .unselected-tier-svg {
    height: 16px;
    width: 16px;
  }
  .selected-tier-svg {
    height: 18px;
    width: 18px;
  }
  .horizontalline {
    width: 8px;
  }
}

.plan-feature-table {
  // colors
  .header {
    color: #17437d;
  }
  .unselected-tier {
    color: #616161;
  }
  .selected-tier {
    color: #17437d;
  }
  .selected-tier-svg {
    color: #17437d;
  }

  .unselected-tier-svg {
    color: #9e9e9e;
  }
  .feature-name {
    color: #5b5b5b;
  }
}
