.dual_layout {
  .MuiPaper-root {
    height: 100vh;
  }

  .main-right {
    /* background-color: #0D5ECB !important; */
    background: radial-gradient(
      234.43% 114.82% at 69.32% 45.72%,
      #dbf4fa 30.73%,
      #2f80ec 100%
    );
    overflow: auto;
    position: relative;
    border-radius: 16px 0 0 16px;
  }

  @media (max-width: 900px) {
    .main-right {
      border-radius: 0px 0 0 0px;
    }
  }
  .margin-top-15 {
    margin-top: 15px;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-top-5 {
    margin-top: 5px;
  }

  .margin-top--25 {
    margin-top: -25px;
  }
  .loading-lottie div {
    display: flex;
    justify-content: center;
  }
  .loading-lottie p {
    text-align: center;
  }
  .small-logo {
    padding-bottom: 10px;
    /* filter: drop-shadow(0 0 0.75rem #0046a0); */
  }
}
