.payment_login {
  fieldset {
    border-color: aliceblue !important;
    // background-color: #f2f2f7;
  }
  .MuiFormControl-root {
    width: 100%;
  }
  .Mui-error {
    border: #d63b3a 1px solid;
  }
  .MuiInputBase-root {
    width: 100%;
    background-color: #f2f2f7;
    border-radius: 10px;
  }
  .MuiFormHelperText-root {
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    border: none !important;
  }
  .header {
    font-size: 36px;
    font-weight: 500;
    color: #273a6b;
    margin-bottom: 8px;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 400;
    color: #313957;
    margin-bottom: 12px;
  }
  .login_button {
    background-color: #17437d !important;
    width: 100% !important;
    border-radius: 12px !important;
    height: 52px !important;
    margin-top: 2px;
    text-transform: capitalize;
    font-size: 16px;
  }
  .MuiLoadingButton-loadingIndicator {
    color: #f2f2f7;
  }
  .pass_button_contaier {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 8px;
  }
  .pass_button {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #2f80ed;
  }
  .signup {
    color: #2f80ed;
    text-decoration: none;
    cursor: pointer;
  }
}
