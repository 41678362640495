.subscription-card {
  border-radius: 16px;
  border: 1px solid #d3e4ff;
  background-color: rgba(3, 169, 244, 0.2);
  overflow: hidden;
  position: relative;

  &__content {
    padding: 20px 24px 20px 24px;
    position: relative;
    z-index: 1;

    & > * {
      margin-bottom: 12px;
    }
    a {
      text-decoration: none;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 0;
      color: #273a6b;
      font-weight: 600;
      font-size: 1.1em;
    }

    &__chip {
      padding: 4px 10px;
      border-radius: 100px;
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
  &__headline {
    margin: 0;
    margin-bottom: 8px;
    color: #273a6b;
    font-weight: 600;
    font-size: 1.5em;
  }

  &__info,
  &__subtitle,
  &__auto-renew,
  &__customer-support,
  &__action {
    p {
      margin: 0;
      color: #273a6b;
    }
  }

  &__info {
    background-color: rgba(3, 169, 244, 0.3);
    padding: 8px 12px;
    border-radius: 16px;
  }

  &__auto-renew {
    p {
      &:first-child {
        color: #273a6b;
        font-weight: 520;
        font-size: 0.8em;
      }
      &:last-child {
        color: #273a6b;
        font-weight: 600;
        font-size: 1em;
      }
    }
  }

  &__customer-support {
    width: 100%;

    button {
      background: none;
      border: none;
      color: #0d47a1;
      cursor: pointer;
      padding: 0;
      box-shadow: none;
      text-transform: capitalize;
      font-size: 1em;
    }
  }

  &__action {
    margin-bottom: 0px;
    button {
      width: 100%;
      background-color: #0d47a1;
      color: #fff;
      border: none;
      padding: 12px;
      border-radius: 12px;
      text-transform: capitalize;
      font-size: 1em;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: #0d47a1;
      }
    }
  }
  .help-card {
    background-color: #cedbf6;
    border-radius: 12px;
    margin-bottom: 8px;
    cursor: pointer;
    scale: 1;
    transition: scale 0.3s;

    &:hover {
      scale: 1.02;
    }

    .MuiTypography-root {
      color: #0d47a1;
    }

    .MuiSvgIcon-root {
      color: #0d47a1;
    }
    button {
      background-color: #fff;
      font-size: 1em;
    }
  }
}
