$transition-duration: 0.9s;
$transition-ease: ease;

@mixin common-styles {
  color: inherit;
  transition: all $transition-duration $transition-ease;

  .layout-title {
    transition: color $transition-duration $transition-ease;
  }
  .striked-text {
    text-decoration: line-through;
    color: #707070;
  }

  .MuiPaper-root {
    transition: background $transition-duration $transition-ease;
  }

  .plan-duration-selection {
    transition: background $transition-duration $transition-ease;
    border-radius: 20px 20px 0px 0px;
    a {
      font-size: 12px;
      margin: 0px 8px;
    }
  }

  .primary-button,
  .secondary-button {
    border-radius: 50px;
    height: 48px;
    text-transform: capitalize;
    transition: background-color $transition-duration $transition-ease,
      color $transition-duration $transition-ease;
  }

  .primary-button {
    font-size: 16px;
    font-weight: 600;
  }

  .secondary-button {
    font-size: 14px;
  }
}

.plan-details {
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-16 {
    margin-bottom: 16px;
  }

  .base-style {
    @include common-styles;
    color: #212121 !important;

    .layout-title {
      color: #212121 !important;
    }

    .MuiPaper-root {
      background: white !important;
    }

    .plan-duration-selection {
      background: #f2f2f7 !important;
      a {
        color: #7c7c7e !important;
      }
    }

    .primary-button {
      background-color: #17437d !important;
      color: #ffffff !important;
    }

    .secondary-button {
      background-color: #ffffff !important;
      color: #595959 !important;
    }
    // Selection widget
    .plan-option-button {
      background-color: #fff !important;
      color: #000000 !important;
      .plan-option-button-container {
        .MuiCheckbox-root {
          svg {
            fill: gray !important;
          }
        }
      }
      &.active {
        background-color: #17437d !important;
        color: #fff !important;
        .plan-option-button-container {
          .MuiCheckbox-root {
            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
    // layout
    .ripple-background .circle {
      box-shadow: 0px 0px 12px 12px rgb(23, 67, 125, 0.1) !important;
    }
    .mobile-first-layout {
      background: linear-gradient(
          0deg,
          rgb(23 67 125 / 70%),
          rgb(23 67 125 / 70%)
        ),
        url("../../../mobile-back-bg.png") !important;
      background-repeat: repeat !important;
      background-position: center !important;
      background-size: calc(100px + 200px) !important; // Adjust as needed
    }
    // table
    .plan-feature-table {
      // colors
      .header {
        color: #17437d !important;
      }
      .unselected-tier {
        color: #616161 !important;
      }
      .selected-tier {
        color: #17437d !important;
      }
      .selected-tier-svg {
        color: #17437d !important;
      }

      .unselected-tier-svg {
        color: #9e9e9e !important;
      }
      .feature-name {
        color: #5b5b5b !important;
      }
    }
    // benifits
    .feature-accordion {
      .accordion-chip {
        background-color: #17437d !important;
        color: white !important;
      }
      .accordion-title {
        color: #17437d !important;
      }
      .feature-name .feature-description {
        color: #424242;
      }
      .MuiAccordionSummary-expandIconWrapper {
        svg {
          color: #424242;
        }
      }
    }
  }

  .mid-style {
    @include common-styles;
    color: #000000 !important;

    .layout-title {
      color: #000000 !important;
    }

    .MuiPaper-root {
      background: #f8c77e !important;
    }

    .plan-duration-selection {
      background: #e7a33e !important;
      a {
        color: #513916 !important;
      }
    }

    .primary-button {
      background-color: #000000 !important;
      color: #ffffff !important;
    }

    .secondary-button {
      background-color: #f1c88b !important;
      color: #595959 !important;
    }
    // Selection widget
    .plan-option-button {
      background-color: #edba70 !important;
      color: #000000 !important;
      .plan-option-button-container {
        .MuiCheckbox-root {
          svg {
            fill: gray !important;
          }
        }
      }
      &.active {
        background-color: #000000 !important;
        color: #fff !important;
        .plan-option-button-container {
          .MuiCheckbox-root {
            svg {
              fill: #fff !important;
            }
          }
        }
      }
    }
    // layout
    .ripple-background .circle {
      box-shadow: 0px 0px 12px 12px rgb(248, 199, 126, 0.12) !important;
    }
    .mobile-first-layout {
      background: linear-gradient(
          0deg,
          rgb(133 113 82 / 70%),
          rgb(133 113 82 / 70%)
        ),
        url("../../../mobile-back-bg.png") !important;
      background-repeat: repeat !important;
      background-position: center !important;
      background-size: calc(100px + 200px) !important; // Adjust as needed
    }
    // table
    .plan-feature-table {
      // colors
      .header {
        color: #000000 !important;
      }
      .unselected-tier {
        color: #9a7b4e !important;
      }
      .selected-tier {
        color: #000000 !important;
      }
      .selected-tier-svg {
        color: #000000 !important;
      }

      .unselected-tier-svg {
        color: #9a7b4e !important;
      }
      .feature-name {
        color: #635032 !important;
      }
    }
    // benifits
    .feature-accordion {
      .accordion-chip {
        background-color: #17437d !important;
        color: white !important;
      }
      .accordion-title {
        color: #17437d !important;
      }
      .feature-name .feature-description {
        color: #424242;
      }
      .MuiAccordionSummary-expandIconWrapper {
        svg {
          color: #424242;
        }
      }
    }
  }

  .pro-style {
    @include common-styles;
    color: #ffffff !important;

    .layout-title {
      color: #ffffff !important;
    }

    .MuiPaper-root {
      background: #000000 !important;
    }

    .plan-duration-selection {
      background: #39363d !important;
      a {
        color: #bab9bb !important;
      }
    }

    .primary-button {
      background-color: #ffffff !important;
      color: #000000 !important;
    }

    .secondary-button {
      background-color: #57545a !important;
      color: #ffffff !important;
    }
    // Selection widget
    .plan-option-button {
      background-color: #48464d !important;
      color: #fff !important;
      .plan-option-button-container {
        .MuiCheckbox-root {
          svg {
            fill: gray !important;
          }
        }
      }
      &.active {
        background-color: #fff !important;
        color: #000000 !important;
        .plan-option-button-container {
          .MuiCheckbox-root {
            svg {
              fill: #000000 !important;
            }
          }
        }
      }
    }
    // layout
    .ripple-background .circle {
      box-shadow: 0px 0px 12px 12px rgb(134, 134, 134, 0.12) !important;
    }
    .mobile-first-layout {
      background: linear-gradient(
          0deg,
          rgb(40 40 40 / 70%),
          rgb(40 40 40 / 70%)
        ),
        url("../../../mobile-back-bg.png") !important;
      background-repeat: repeat !important;
      background-position: center !important;
      background-size: calc(100px + 200px) !important; // Adjust as needed
    }
    // table
    .plan-feature-table {
      // colors
      .header {
        color: white !important;
      }
      .unselected-tier {
        color: #999999 !important;
      }
      .selected-tier {
        color: white !important;
      }
      .selected-tier-svg {
        color: white !important;
      }

      .unselected-tier-svg {
        color: #616161 !important;
      }
      .feature-name {
        color: white !important;
      }
    }
    // benifits
    .feature-accordion {
      .accordion-chip {
        background-color: #ffffff !important;
        color: #17437d !important;
      }
      .accordion-title {
        color: #ffffff !important;
      }
      .feature-name {
        color: #ffffff;
      }
      .feature-description {
        color: #ffffff;
      }
      .MuiAccordionSummary-expandIconWrapper {
        svg {
          color: #ffffff;
        }
      }
      .MuiPaper-elevation {
        border: 1px solid rgba(255, 255, 255, 0.4);
      }
      .feature-item:before {
        background-color: #ffffff !important;
      }
    }
  }
}
.plan-selection-tab {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  .MuiBox-root {
    border-bottom: 0px;
  }
  .MuiTabs-scroller {
    padding: 3px;
    background-color: #f3f2f7;
    border-radius: 10px;
    height: 45px;
    .MuiTab-root {
      text-transform: capitalize;
      height: 38px;
      padding: 0px !important;
      min-height: 29px !important;
      font-weight: 600 !important;
      font-size: 0.95rem;
    }
    .Mui-selected {
      background-color: white;
      border-radius: 8px;
      font-weight: 600 !important;
      color: #000000;
      box-shadow: 0px 3.88px 10.35px rgba(0, 0, 0, 0.12);
    }
    .MuiTabs-indicator {
      height: 0px;
    }
  }
}
