@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
}
body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}
h1,
h2,
h3,
h4,
h5,
h6,
label,
span,
p {
  margin: 0;
  font-family: "Fira Sans", sans-serif;
}
main {
  background-color: black;
  overflow: hidden;
}
