// Define colors
$blue-dark: #072651;
$blue-light: #edf7fa;
$blue-lighter: #d7efff; // lighter shade of light blue for gradient

// Mixin for responsive full-height section
@mixin full-height {
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin size($size) {
  width: $size;
  height: $size;
}

.mobile-first-layout {
  @include full-height;
  background: linear-gradient(0deg, rgb(12 81 172 / 70%), rgb(12 81 172 / 70%)),
    url("../mobile-back-bg.png");
  background-repeat: repeat;
  background-position: center;
  background-size: calc(100px + 200px); // Adjust as needed
  transition: all 2s ease;

  &::after {
    content: "";
    position: absolute;
    top: 200px;
    left: 0;
    width: 100%;
    height: calc(100% - 200px);
    background: linear-gradient(to bottom, $blue-light 20%, $blue-lighter 100%);
    z-index: -1;
  }

  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .logo {
    color: #ffffff;
    padding-top: 0px;
    padding-bottom: 0px;
    svg {
      height: 120px;
      width: 130px;
      path {
        fill: #ffff;
      }
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 620px !important;
    z-index: 2;
  }
  .ripple-container {
    padding-top: 10px;
    position: fixed;
  }

  .paper {
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 30px 30px 0px 0px;
    min-height: 90vh;
    min-width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.5)
    );
    // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: relative;
  }
  .layout-title {
    text-align: center;
    font-size: 1.5em;
    color: #073461;
    padding-bottom: 24px;
    font-weight: 600;
  }
}

.ripple-background {
  position: fixed;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
  .circle {
    position: absolute;
    border-radius: 50%;
    background: white;
    animation: ripple 80s infinite;
    box-shadow: 0px 0px 6px 1px #c4e9f580;
    transition: all 2s ease;
  }

  .large {
    @include size(50vw);
    @media (max-width: 1200px) {
      @include size(80vw);
    }
    @media (max-width: 1000px) {
      @include size(100vw);
    }
    @media (max-width: 800px) {
      @include size(120vw);
    }
  }

  .xlarge {
    @include size(60vw);
    @media (max-width: 1200px) {
      @include size(90vw);
    }
    @media (max-width: 1000px) {
      @include size(110vw);
    }
    @media (max-width: 800px) {
      @include size(130vw);
    }
  }

  .xxlarge {
    @include size(70vw);
    @media (max-width: 1200px) {
      @include size(100vw);
    }
    @media (max-width: 1000px) {
      @include size(120vw);
    }
    @media (max-width: 800px) {
      @include size(140vw);
    }
  }

  .shade1 {
    opacity: 0.5;
  }

  .shade2 {
    opacity: 0.7;
  }

  .shade3 {
    opacity: 0.9;
  }

  @keyframes ripple {
    0% {
      transform: scale(0.9);
    }

    50% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(0.9);
    }
  }
}
