.main-box {
  padding: 16px;
  margin: 0 auto;
  /* margin-top: 30%; */
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #212121;
}
.email-verify-success {
  padding-bottom: 10%;
}
.email-verify-success .content {
  padding-left: 2%;
}

.email-verify-success svg {
  width: 120px !important;
}
.email-verify-success .content h2 {
  font-size: 40px;
}
.email-verify-success .content p {
  font-size: 20px;
}
